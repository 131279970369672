import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

const Article = () => (
  <NewsArticle
    title="The Intermediary: The Interview… Chris Pearson, head of intermediary mortgages, HSBC UK"
  >
   <h1 className='page-title'>The Intermediary: The Interview… Chris Pearson, head of intermediary mortgages, HSBC UK</h1>
   <img src='/news-images/08-april-the-interview-chris-pearson-head-of-intermediary-mortgages.jpg' alt='Chris Pearson' title='Chris Pearson photo' className='news-article__lead-image'/>
   <p>In the first of a series of interviews with key industry figures, The Intermediary speaks to Chris Pearson, head of intermediary mortgages at banking heavyweight HSBC UK.</p>
   <p>In this interview, Pearson gives his take on the market, the green agenda and also why brokers are key to HSBC’s plans for both the present and future.</p>
   <p><strong>You’ve been responsible for intermediary mortgage distribution at HSBC UK for over five years now. How has the market changed during that time?</strong></p>
   <p>The market has in fact remained stoically consistent in terms of brokers, lenders and indeed the Regulator working together to provide the best possible experience and outcomes for customers.</p>
   <p>Despite numerous challenges, from rapidly adapting to non-face-to-face interactions, through to handling huge volume spikes at certain points in 2021, lenders and brokers have come together to keep the market rolling on for customers.</p>
   <p>Customers selecting brokers as their preferred channel of choice has also remained consistently the dominant channel.</p>
   <p>I’d obviously like to think (I would, wouldn’t I) that the emergence of HSBC into the broker sector has prompted all participants in the market into making their own journey improvements for brokers and customers alike, so perhaps HSBC has been one of the key change catalysts for the wider sector over the last few years.</p>
   <p>Technology is playing its role in helping all of us serve customers more efficiently, ‘evolution not revolution’ would be my summary so far.</p>
   <p>I’d also like to highlight the genuine shift I’ve seen in terms of diversity and inclusion. There’s still a long way to go, but I think the last five years have seen a very positive shift in the right direction and we need that to continue.</p>
   <p><strong>How important are intermediaries to HSBC?</strong></p>
   <p>Intermediaries are very important to HSBC and we hugely value the brilliant relationships we’ve developed with them over the last few years.</p>
   <p>Our investment in platforms, technology, products, service and most importantly our people who serve the sector should be a very clear signal that we’re a big fan of brokers.</p>
   <p>Look out for more of that in the future, we’re here to stay.</p>
   <p><strong>We’ve seen the rise of green mortgages over the past 12 months. What do you see as the future of sustainable products?</strong></p>
   <p>Let me start by saying what we don’t want to see in this space, which is the totemic approach of a few ‘tick box’ green products that give that veneer impression of real solutions.</p>
   <p>We do need to look at our own climate footprints and what we stand for, that of ourselves individually and that of all our businesses and business partners.</p>
   <p>That has to be the start point to show that we’re serious in making tangible change, in conjunction with our approach to product design or other financial solutions.</p>
   <p>HSBC UK and indeed HSBC globally have made some very substantial commitments to underpin how serious we are about the climate and sustainability agenda, that’s all been widely publicised so I won’t dwell on it here.</p>
   <p>In terms of mortgages, we’ll have more to say on that in the near future once we’ve completed our assessment of the products and services that we think will make the most difference.</p>
   <p>This is not a ‘once and done’ approach, future product design will undoubtedly evolve to ensure climate impact is captured.</p>
   <p><strong>What can we expect to see from HSBC on the mortgage front over the coming months? Anything new and exciting in the pipeline?</strong></p>
   <p>I can’t say too much in terms of future products for obvious reasons, but let’s just say that I’m personally as excited and fired up as I’ve ever been for the year ahead in terms of HSBC’s plans for our intermediary business and growing an already world-class team of people.</p>
   <p>Making life simpler and faster for our brokers and customers is always going to be the cornerstone of our plans, I can certainly say that with confidence.</p>
   <p><strong>How do you see the property market performing over the next 12 months?</strong></p>
   <p>Last year was a bit of a one-off in terms of transaction volumes, we all know that, driven by market stimuli and a desire by people up and down the UK to move home.</p>
   <p>Being in several lockdowns has certainly changed many people’s attitudes to where and how they want to live.</p>
   <p>2022 I think will remain solid for the market in terms of transactions, if not hitting the heights of last year and the market will continue to offer good opportunities for brokers across purchase, remo and, of course, product transfers.</p>
   <p>So let’s go and grab it and don’t forget to re-visit those really important protection conversations, the mortgage market has remained pretty stable but our customers’ lives are always changing so let’s get talking to them and protecting them appropriately.</p>
   <p><strong>If you could give a mortgage intermediary one tip what would it be?</strong></p>
   <p>If every business decision you made came with the filter… “does this improve things for my customers?” …great things tend to happen. Try it.</p>
  </NewsArticle>
)

export default Article